import { Box, Button, TextField, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import styled, { keyframes } from 'styled-components';
import backgroundImageUrl from '../../assets/backgroundImg.jpeg';
const backgroundImageUrl1 =
  'https://media.istockphoto.com/id/1424941985/vector/pizza-slice-seamless-vector-background-isolated-o-white-background-with-beans-maize-or-corn.jpg?s=1024x1024&w=is&k=20&c=2mA_hviu-jJo4CN0_MPgx0ZF7BN1NMReLIWTBIQPpEw=';
const backgroundImageUrl2 =
  'https://img.freepik.com/free-photo/top-view-cheese-pizza-with-olives-pepper-tomatoes-dark-surface_179666-34874.jpg?t=st=1724926003~exp=1724929603~hmac=c4f990fbf0a7a93fd395817b65ef2f8bef648296a46582bd530803c2c20a690d&w=740';
// Styled components
export const Body = styled.div`
  font-family: Arial, sans-serif;
  margin: 20px;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const H1 = styled.h1`
  margin: 0;
`;

export const H2 = styled.h2`
  margin: 20px 0 10px 0;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

export const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

// export const Button = styled.button`
//   padding: 16px 0px;
//   width: 40%;
//   background-color: #4caf50;
//   color: white;
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;

//   &:hover {
//     background-color: #45a049;
//   }
// `;

export const Stars = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-top: -10px;
`;

export const StarInput = styled.input`
  display: none;
`;

export const StarLabel = styled.label`
  font-size: 24px;
  cursor: pointer;
  color: #f0ad4e;
`;
export const CustomerData = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  //   gap: 15px;
  max-width: 100%;
  padding: 10px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 150px;
  flex-flow: wrap;
`;

export const FormGroup1 = styled.div`
  display: flex;
  flex: 1;
  min-width: 150px;
  gap: 1rem;
`;

export const RatingSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
`;

export const SubmitButton = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
`;

// submit page css

export const SubmitContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Container = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  width: 600px;
  text-align: center;
  height: 300px;
`;

export const Confirmation = styled.div`
  margin-top: 20px;
  color: #4caf50;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const HeadingTage = styled.h1`
  margin-bottom: 20px;
  font-size: 32px; /* Increased font size for the heading */
  color: #333;
`;

export const CheckIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: #4caf50;
  color: white;
  border-radius: 50%;
  font-size: 5rem;
  line-height: 40px;
`;
export const StyledTextField = styled(TextField)`
  & .MuiOutlinedInput-root {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    // border-radius: 4px; // Optional: round corners
  }
`;

export const BackgroundImageBox = styled(Box)`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px; /* gap: 2 in MUI's theme is typically 16px */
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${backgroundImageUrl});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.1;
    z-index: 1;
  }

  & > * {
    position: relative;
    z-index: 2;
  }
`;

// Dashboard Section
export const BranchOfMonthBox = styled(Box)`
  box-shadow: 0px 0px 2px 1px #cbd5e0;
  margin: 10px;
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  // width: 100%;
  width: 48%;
  boxsizing: border-box;
`;

export const ProgressBarBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});
export const MonthYearlyDayBox = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap'
});

export const TitleBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center'
});

//////////////// tackeFeedback style ////////////////

export const TakeFeedbackParentSection = styled(Box)(({ theme }) => ({
  minHeight: '300px',
  width: '250px',
  boxShadow: theme.palette.customShadows.medium,
  borderRadius: '10px',
  height: 'fit-content',
  border: '1px solid gray',
  // border: '1px solid rgb(153, 117, 117)',
  '&:hover': {}
}));

export const TakeUpperSection = styled(Box)(({ theme }) => ({
  height: '27%',
  backgroundColor: theme?.palette?.primary?.dark,
  borderRadius: '10px 10px 0 0px',
  // textAlign: 'left',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  padding: '10px',
  // fontSize: '14px',
  fontWeight: '600'
  // gap: '20px'
}));

export const TakeUpperTypography = styled(Typography)(() => ({
  fontSize: '16px',
  fontWeight: 600,
  // color: '#cbc4ff',
  color: 'black'
}));

export const ButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
  boxShadow: 'rgb(170, 170, 170) 0px 0px 10px inset',
  border: `2px solid ${theme.palette.white.main}`,
  borderRadius: '50px',
  height: '50px',
  padding: '10px'
}));

export const TakeTimerRing = styled(Box)(({ theme, lower }) => ({
  border: `2px solid ${theme.palette.white.main}`,
  borderRadius: '50px',
  width: '100%',
  height: lower ? '50px' : '60px',
  boxSizing: 'content-box',
  display: 'flex',
  flexDirection: lower ? 'row' : 'column',
  justifyContent: lower ? 'space-around' : 'center',
  alignItems: 'center',
  background: theme.palette.white.main,
  boxShadow: lower
    ? 'rgb(170, 170, 170) 0px 0px 10px inset'
    : 'rgb(70, 70, 69) 0px 0px 10px inset'
}));

export const TakeTimerRingChild = styled(Box)(() => ({
  color: 'black',
  fontSize: '25px'
}));

export const TakeMiddleSection = styled(Box)(({ states }) => ({
  minHeight: '200px'
}));

export const CustomerInfo = styled(Box)(({ background }) => ({
  borderBottom: '1px solid gray',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px',
  height: '50px',
  backgroundColor: background ? 'white' : '#eee'
}));

export const TakeLowerSection = styled(Box)(() => ({
  borderTop: '1px solid #EB6440',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  padding: '10px 0px'
}));
export const NoOrderFoundBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
  fontSize: 'clamp(1rem, 2vW + 0.5rem, 1.5rem)',
  fontWeight: 500,
  textAlign: 'center',
  padding: '20px 0px',
  color: '#999'
}));

export const HeaderBox = styled(Box)(({ smallScreen }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  flexWrap: smallScreen ? 'wrap' : '',
  flexDirection: smallScreen ? 'column' : '',
  gap: smallScreen ? '10px' : '',
  boxShadow: '0px 0px 2px 1px #cbd5e0',
  margin: '10px',
  padding: '7px 0px',
  position: 'sticky',
  top: '0',
  zIndex: '1300'
}));

export const CardParentBox = styled(Box)(() => ({
  display: 'flex',
  // alignItems: 'center',
  justifyContent: 'space-around',
  margin: '10px',
  padding: '7px 0px',
  overflow: 'auto',
  height: '100%'
}));

export const TakeDoneButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '30px',
  fontSize: '15px',
  fontWeight: '700',
  height: '40px',
  width: '100px',
  color: theme.palette.white.main,
  '&:hover': {
    boxShadow: theme.palette.customShadows.light,
    backgroundColor: theme.palette.primary.hover
  }
}));

export const RotatingIcon = styled(RefreshIcon)(({ isRotating, checked }) => ({
  color: checked ? 'blue' : 'gray',
  cursor: 'pointer',
  transition: 'transform 0.5s ease-in-out',
  fontSize: '2rem',
  ...(isRotating && {
    animation: 'spin 1s linear infinite'
  }),
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' }
  }
}));

